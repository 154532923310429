import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Login } from "../components/Login";

const DutchLoginPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Inloggen" lang="nl" pathName={location.pathname} />
    <Login language="nl" />
  </>
);

export default DutchLoginPage;
